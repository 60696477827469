var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-card',{attrs:{"type":"storm5"}},[_c('v-card-title',{staticClass:"tw-flex tw-justify-between tw-uppercase"},[_c('span',[_vm._v("Mobile Two-Factor Authentication")]),_c('ax-button',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c('v-card-text',[(_vm.enabled)?_c('div',[(_vm.authChangeType)?_c('p',{staticClass:"mb-0 warning--text"},[_vm._v(" To enable "+_vm._s(_vm.authChangeType)+" two-factor authentication, you must disable mobile two-factor authentication. ")]):_vm._e(),_c('p',[_vm._v(" To disable mobile two-factor authentication, please enter in the authentication code below. ")]),_c('div',{staticClass:"tw-flex"},[_c('label',{staticClass:"text-field-label",attrs:{"for":"tfa-disable"}},[_vm._v("Authentication Code")]),_c('ax-text-field',{attrs:{"id":"tfa-disable","disabled":_vm.tfaCode.validdisable,"error-messages":_vm.tfaCode.errordisable
              ? ['Invalid authentication code, please try again.']
              : [],"maxlength":"6","autofocus":""},on:{"input":function (val) { return _vm.onCodeInput(val, 'disable'); }},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.tfaCode.validdisable)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.mdiCheckBold))]):_vm._e()]},proxy:true}],null,false,1479131557)})],1)]):_c('div',[(_vm.currentTfa === 'email')?_c('p',{staticClass:"warning--text"},[_vm._v(" By enabling mobile two-factor authentication, you will be disabling email two-factor authentication. ")]):_vm._e(),_c('ol',{staticClass:"tw-list-decimal"},[_c('li',[_c('h3',{staticClass:"tw-text-base tw-text-white"},[_vm._v(" Download a mobile (time-based one time password, TOTP) authenticator app. ")]),_c('p',[_vm._v("Use an app such as Google Authenticator or Authy.")])]),_c('li',{staticClass:"tw-mb-4"},[_c('h3',{staticClass:"tw-text-base tw-text-white"},[_vm._v(" Scan this barcode with your app. ")]),_c('p',[_vm._v(" Scan the image below with the two-factor authentication app on your mobile device. ")]),_c('div',{staticClass:"tw-flex tw-justify-center"},[_c('v-img',{attrs:{"src":_vm.barcode,"alt":"Two-factor Authentication QR Code","max-width":"12rem","contain":""}})],1)]),_c('li',[_c('h3',{staticClass:"tw-text-base tw-text-white"},[_vm._v(" Enter the six-digit code from the app. ")]),_c('p',[_vm._v(" After scanning the barcode image above, the app will display a six-digit code that you can enter below to pair with your Automox account. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"tw-flex"},[_c('label',{staticClass:"text-field-label",attrs:{"for":"tfa1"}},[_vm._v("Authentication Code")]),_c('ax-text-field',{attrs:{"id":"tfa1","disabled":_vm.tfaCode.valid1,"error-messages":_vm.tfaCode.error1
                      ? ['Invalid authentication code, please try again.']
                      : [],"maxlength":"6","autofocus":""},on:{"input":function (val) { return _vm.onCodeInput(val, 1); }},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.tfaCode.valid1)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.mdiCheckBold))]):_vm._e()]},proxy:true}])})],1),(_vm.tfaCode.valid1)?_c('div',{staticClass:"tw-flex"},[_c('label',{staticClass:"text-field-label",attrs:{"for":"tfa2"}},[_vm._v("Next Authentication Code")]),_c('ax-text-field',{attrs:{"id":"tfa2","disabled":_vm.tfaCode.valid2,"error-messages":_vm.tfaCode.error2
                      ? ['Invalid authentication code, please try again.']
                      : [],"maxlength":"6","autofocus":""},on:{"input":function (val) { return _vm.onCodeInput(val, 2); }},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.tfaCode.valid2)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.mdiCheckBold))]):_vm._e()]},proxy:true}],null,false,2628170915)})],1):_vm._e()])],1)],1)])])]),_c('v-card-actions',{staticClass:"tw-justify-end tw-px-6 tw-pb-4"},[_c('ax-button',{attrs:{"mode":"secondary"},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_vm._v("Cancel")]),_c('ax-button',{attrs:{"disabled":(!_vm.enabled && !(_vm.tfaCode.valid1 && _vm.tfaCode.valid2)) ||
        (_vm.enabled && !_vm.tfaCode.validdisable),"mode":"primary"},on:{"click":_vm.toggleTwoFactor}},[_vm._v(" "+_vm._s(_vm.authChangeType ? 'Change' : _vm.enabled ? 'Disable' : 'Enable')+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }