import Vue from 'vue';
import { requiredIf, url } from 'vuelidate/lib/validators';

export default Vue.extend({
  computed: {
    entityIdError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { entity_id } = (this as any).$v.formData;

      if (!entity_id.$dirty) {
        return errors;
      }
      if (!entity_id.required) {
        errors.push('Entity ID is required.');
      }

      return errors;
    },
    x509Error() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { x509_cert } = (this as any).$v.formData;

      if (!x509_cert.$dirty) {
        return errors;
      }
      if (!x509_cert.required) {
        errors.push('x509 is required.');
      }

      return errors;
    },
    loginUrlError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { sso_url } = (this as any).$v.formData;

      if (!sso_url.$dirty) {
        return errors;
      }

      if (!sso_url.required) {
        errors.push('Login URL is required.');
      }
      if (!sso_url.url) {
        errors.push('Login URL is not a valid URL.');
      }

      return errors;
    },
    xmlError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { xml } = (this as any).$v.formData;

      if (!xml.$dirty) {
        return errors;
      }

      if (!xml.required) {
        errors.push('XML configuration is required.');
      }

      return errors;
    },
    logoutUrlError() {
      const errors: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { logout_url } = (this as any).$v.formData;

      if (!logout_url.$dirty) {
        return errors;
      }

      if (!logout_url.url) {
        errors.push('Logout URL is not a valid URL.');
      }

      return errors;
    },
  },
  validations: {
    formData: {
      entity_id: {
        required: requiredIf((model) => !model.useXML),
      },
      x509_cert: {
        required: requiredIf((model) => !model.useXML),
      },
      sso_url: {
        required: requiredIf((model) => !model.useXML),
        url,
      },
      xml: {
        required: requiredIf((model) => model.useXML),
      },
      logout_url: {
        url,
      },
    },
  },
});
